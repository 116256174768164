<template>
  <div
    v-if="round"
    class="container"
  >
    <q-btn
      :class="`text-${color || 'white'}`"
      round
      dense
      icon="cached"
      style="margin-right: 15px;"
      @click.stop="memberResetPassword()"
    >
      <q-tooltip>
        {{ $t('reset_password') }}
      </q-tooltip>
    </q-btn>
    <q-btn
      v-if="!member.is_blocked"
      :class="`text-${color || 'white'}`"
      round
      dense
      icon="block"
      style="margin-right: 15px;"
      @click.stop="memberBlock()"
    >
      <q-tooltip>
        {{ $t('block') }}
      </q-tooltip>
    </q-btn>
    <q-btn
      v-if="member.is_blocked"
      :class="`text-${color || 'white'}`"
      round
      dense
      icon="check_circle_outline"
      style="margin-right: 15px;"
      @click.stop="memberUnblock()"
    >
      <q-tooltip>
        {{ $t('unblock') }}
      </q-tooltip>
    </q-btn>
    <q-btn
      :class="`text-${color || 'white'}`"
      round
      dense
      icon="settings"
      style="margin-right: 15px;"
      @click.stop="$router.push({ hash: '/user-settings', query: { username: member.username } })"
    >
      <q-tooltip>
        {{ $t('account_settings') }}
      </q-tooltip>
    </q-btn>
  </div>
  <div
    v-else
    class="container labelled"
  >
    <q-btn
      :class="`text-${color || 'white'}`"
      color="light"
      label="Reset Password"
      style="margin-top: 15px;"
      @click.stop="memberResetPassword()"
    />
    <q-btn
      v-if="!member.is_blocked"
      :class="`text-${color || 'white'}`"
      color="light"
      label="Block Member"
      style="margin-top: 15px;"
      @click.stop="memberBlock()"
    />
    <q-btn
      v-if="member.is_blocked"
      :class="`text-${color || 'white'}`"
      color="light"
      label="Unblock Member"
      style="margin-top: 15px;"
      @click.stop="memberUnblock()"
    />
  </div>
</template>

<script type="text/javascript">
export default {
  props: {
    member: {
      type: Object
    },
    color: {
      type: String
    },
    round: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    memberUnblock () {
      this.$q.dialog({
        title: this.$t('confirm.confirm'),
        message: this.$t('confirm.unblock_member'),
        ok: this.$t('confirm.confirm'),
        cancel: this.$t('cancel')
      })
        .onOk(() => {
          this.$store.dispatch('members/unblockUser', {
            user: this.member
          })
        })
    },
    memberBlock () {
      this.$q.dialog({
        title: this.$t('confirm.confirm'),
        message: this.$t('confirm.block_member'),
        ok: this.$t('confirm.confirm'),
        cancel: this.$t('cancel')
      })
        .onOk(() => {
          this.$store.dispatch('members/blockUser', {
            user: this.member
          })
        })
    },
    memberResetPassword () {
      this.$q.dialog({
        title: this.$t('confirm.confirm'),
        message: this.$t('confirm.reset_password'),
        ok: this.$t('confirm.confirm'),
        cancel: this.$t('cancel')
      }).onOk(() => {
        this.$store.dispatch('members/resetUserPassword', {
          user: this.member
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.container
  display inline-block
.labelled
  display flex
  flex-direction column
  justify-content center
  align-items center
  .q-btn
    display block
    width 182px
</style>
